import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from './redux/store';
import Main from './pages/main';
import './assets/css/index.css';
import whatsappLogo from './assets/images/whatsapp.svg';

class App extends Component {
  
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Main />
          <div className="xat">
            <a href="https://wa.me/+34676252174" style={{"textDecoration": "none"}} target="_blank">
              <img src={whatsappLogo} width="50" height="50" alt={"Contacto whatsapp"}/>
            </a>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
